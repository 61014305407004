import clsx from 'clsx';
import type { DiscountResponse, Product } from '../../../../redux/api/retailerOrderListApi/types';
import { IconLoader } from '../../../../assets/icons';
import { getPriceValue } from '../../../../utils/getPriceValue';
import { Price } from '../../../../components/shared';

type Props = {
  product: Product;
  initialDiscounts: Record<string, DiscountResponse>;
  isLoading?: boolean;
  className?: string;
};

export const ProductDetailsModalDiscountedPrice = ({
  product,
  initialDiscounts,
  isLoading,
  className,
}: Props) => {
  if (isLoading) {
    return (
      <div className="flex justify-start">
        <IconLoader className="text-grey-3" />
      </div>
    );
  }
  const currentProductDiscounts = initialDiscounts[product.productId];

  if (!currentProductDiscounts) {
    return null;
  }

  const grossUnitPrice = product.unitPrice;
  const discountedUnitPrice = getPriceValue(currentProductDiscounts.discountedUnitPrice);
  const discountAmount = getPriceValue(currentProductDiscounts.discountAmount);

  return (
    <div
      className={clsx('flex flex-col justify-end items-start text-right min-w-[86px]', className)}
    >
      <Price value={discountedUnitPrice} className="font-subtitle" />
      {Boolean(currentProductDiscounts && discountedUnitPrice) && discountAmount !== 0 && (
        <div className="flex gap-1">
          <Price value={grossUnitPrice} className="text-grey-3 text-badge-sm line-through" />
          <span className="text-primary-brand text-badge-sm">
            -<Price value={grossUnitPrice - discountedUnitPrice} withoutCurrency />
          </span>
        </div>
      )}
    </div>
  );
};
