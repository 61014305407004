import { useState } from 'react';
import clsx from 'clsx';
import { Button, ButtonVariant } from './Button';

type ToggleButtonGroupProps = {
  options: { label: string; value: string }[];
  variant?: ButtonVariant;
  defaultValue?: string;
  onChange?: (value: string) => void;
};

export const ToggleButtonGroup = ({
  options,
  defaultValue,
  onChange,
  variant = 'primary',
}: ToggleButtonGroupProps) => {
  const [selected, setSelected] = useState(defaultValue || options[0].value);

  const handleClick = (value: string) => {
    setSelected(value);
    onChange?.(value);
  };

  return (
    <div className="flex gap-2">
      {options.map((option) => (
        <Button
          key={option.value}
          variant={variant}
          className={clsx('!border !border-grey-4 px-3 py-2.5 rounded-lg', {
            '!border-primary-brand': selected === option.value,
          })}
          onClick={() => handleClick(option.value)}
        >
          <div className="flex text-grey-1 items-center gap-2">
            <span className="w-3.5 h-3.5 rounded-full border border-grey-1 flex items-center justify-center">
              {selected === option.value && (
                <span className="w-2 h-2 bg-primary-brand rounded-full" />
              )}
            </span>
            {option.label}
          </div>
        </Button>
      ))}
    </div>
  );
};
