import type { ChangeEvent } from 'react';
import type { ProductsFilter } from '../constants';
import type { FilterOptions } from '../../../redux/api/retailerOrderListApi/types';
import type { FilterChangeArgs } from '../../../components/Filter/types';
import { Filter, Button, Checkbox } from '../../../components';
import { IconClose, IconSearch } from '../../../assets/icons';
import { Input } from '../../../components/Input';

type PlaceOrderFiltersProps = {
  onFilterChange: (args: FilterChangeArgs) => void;
  filterOptions: FilterOptions | undefined;
  filters: ProductsFilter;
  onFilterClean: (id: string) => void;
  onFiltersReset: () => void;
  searchValue?: string;
  onSearchValueChange: (value: string) => void;
  isOnlyAvailable: boolean;
  setIsOnlyAvailable: (value: boolean) => void;
  isOnlyNew: boolean;
  setIsOnlyNew: (value: boolean) => void;
};

export const BuyProductsFilters = ({
  onFilterChange,
  filters,
  filterOptions,
  onFilterClean,
  onFiltersReset,
  onSearchValueChange,
  searchValue,
  isOnlyAvailable,
  setIsOnlyAvailable,
  isOnlyNew,
  setIsOnlyNew,
}: PlaceOrderFiltersProps) => {
  const handleSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    onSearchValueChange(e.target.value);
  };

  return (
    <div className="flex flex-col bg-white p-3 gap-4 mb-2 rounded-lg">
      <div className="flex flex-wrap gap-4 items-center">
        <div className="w-[350px]">
          <Input
            variant="field"
            value={searchValue || ''}
            onChange={handleSearchValue}
            placeholder="Search"
            iconLeft={<IconSearch />}
            type="text"
          />
        </div>
        <Checkbox
          isSelected={isOnlyAvailable}
          onChange={(isSelected) => setIsOnlyAvailable(isSelected)}
          label="Show Available Only"
          className="w-[14px] !h-[14px]"
        />
        <Checkbox
          isSelected={isOnlyNew}
          onChange={(isSelected) => setIsOnlyNew(isSelected)}
          label="New arrivals only"
          className="w-[14px] !h-[14px]"
        />
      </div>
      <div className="flex flex-wrap gap-4 items-center">
        {filters.map(({ label, id, type, value }) =>
          filterOptions?.[id]?.length ? (
            <Filter
              key={id}
              id={id}
              label={label}
              type={type}
              options={filterOptions?.[id] || []}
              selectedValues={value}
              onFilterClean={onFilterClean}
              onFilterChange={onFilterChange}
            />
          ) : null,
        )}
        <Button variant="grey" size="small" icon={<IconClose />} onClick={onFiltersReset}>
          Clear filters
        </Button>
      </div>
    </div>
  );
};
