import type { ProductCellRendererProps } from '../../../types';
import { Button, Counter } from '../../../components';
import { IconAdd } from '../../../assets/icons';
import { useCart } from '../../../layout/cart/useCart';
import { showToastify } from '../../../utils/common';

export const ActionsCell = ({ cell }: ProductCellRendererProps) => {
  const { changeProductQuantity, getProductQuantityInCart, removeProductsFromCart } = useCart();
  const currentProductCount = getProductQuantityInCart(cell.row.original);

  const handleQuantityChange = (updatedQuantity: number) => {
    if (updatedQuantity > 0) {
      changeProductQuantity(cell.row.original, updatedQuantity);
    } else {
      removeProductsFromCart([cell.row.original.productId]);
    }
  };

  if (!currentProductCount) {
    return (
      <Button
        disabled={cell.row.getValue('unitsAvailable') === 0}
        variant="text"
        size="small"
        icon={<IconAdd />}
        className="m-auto"
        onClick={() => {
          handleQuantityChange(1);
          showToastify('success', 'Product have been successfully added to cart');
        }}
      >
        Buy
      </Button>
    );
  } else {
    return (
      <Counter
        className="m-auto w-[100px]"
        currentCount={currentProductCount}
        max={cell.row.original.unitsAvailable}
        min={0}
        onCountChange={handleQuantityChange}
      />
    );
  }
};
