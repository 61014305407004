import clsx from 'clsx';
import { ProductImage } from '../../../components/shared';
import type { CellContext } from '@tanstack/react-table';
import type { Product } from '../../../redux/api/retailerOrderListApi/types';

type Props = {
  cell: CellContext<Product, string | number>;
  distributorNameMapById: Record<string, string>;
  setProductDetails: (product: Product | undefined) => void;
};

export const ProductImageCell = ({ cell, distributorNameMapById, setProductDetails }: Props) => {
  const name = cell.row.original.distributorId?.toUpperCase()
    ? distributorNameMapById[cell.row.original.distributorId.toUpperCase()]
    : '';

  return (
    <div
      className={clsx(
        'flex items-center gap-2 w-[410px] lg:w-[401px] cursor-pointer hover:text-primary',
      )}
      onClick={() => setProductDetails(cell.row.original)}
    >
      <ProductImage src={cell.row.original.azImageUrl} />
      <div className="flex flex-col whitespace-nowrap overflow-hidden text-ellipsis">
        <div className="overflow-hidden text-ellipsis whitespace-nowrap">{cell.getValue()}</div>
        <div className="whitespace-nowrap overflow-hidden text-ellipsis text-grey-3">
          {name ?? ''}
        </div>
      </div>
    </div>
  );
};
