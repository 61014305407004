import type { DiscountResponse, Product } from '../../../../redux/api/retailerOrderListApi/types';
import { useDiscountVariants } from '../../useDiscountVariants';
import Skeleton from 'react-loading-skeleton';
import { Price } from '../../../../components/shared';

type Props = {
  product: Product;
  productsDiscounts: Record<string, DiscountResponse>;
  currentProductDiscounts: DiscountResponse;
};

export const ProductDetailsModalDiscountsAvailable = ({
  product,
  productsDiscounts,
  currentProductDiscounts,
}: Props) => {
  const { discountVariants: discountsByUnit, isLoading: isLoadingDiscountsByUnit } =
    useDiscountVariants({
      productsDiscounts,
      product,
      discountType: 'byUnit',
    });
  const { discountVariants: discountsByCase, isLoading: isLoadingDiscountsByCase } =
    useDiscountVariants({
      productsDiscounts,
      product,
      discountType: 'byCase',
    });

  return (
    <div className="flex flex-col h-[92px] gap-1 min-h-[92px]">
      {currentProductDiscounts?.discountOnUnits?.length ||
      currentProductDiscounts?.discountOnCase?.length ? (
        <>
          {!!discountsByUnit.length &&
            discountsByUnit.map((discount, index) => (
              <div key={index} className="text-body-md font-subtitle">
                <Price className="line-through" value={product.unitPrice} />{' '}
                <div className="inline-flex">
                  {isLoadingDiscountsByUnit ? (
                    <Skeleton className="rounded-2.5 bg-grey-3 !w-[32px]" />
                  ) : (
                    <Price className="text-primary-brand" value={discount.discountedUnitPrice} />
                  )}
                </div>{' '}
                <span>/ {discount.quantity} bottles</span>
              </div>
            ))}
          {!!discountsByCase.length &&
            discountsByCase.map((discount, index) => (
              <div key={index} className="text-body-md font-subtitle">
                <Price className="line-through" value={product.unitPrice} />{' '}
                <div className="inline-flex">
                  {isLoadingDiscountsByCase ? (
                    <Skeleton className="rounded-2.5 bg-grey-3 !w-[32px]" />
                  ) : (
                    <Price className="text-primary-brand" value={discount.discountedUnitPrice} />
                  )}
                </div>{' '}
                <span>{`/ ${discount.quantity} case${discount.quantity > 1 ? 's' : ''}`}</span>
              </div>
            ))}
        </>
      ) : (
        <>There is no available discounts</>
      )}
    </div>
  );
};
