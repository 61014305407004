import type { CellContext } from '@tanstack/react-table';
import type { Product } from '../../../redux/api/retailerOrderListApi/types';

type Props = {
  cell: CellContext<Product, string | number>;
};

export const UnitsAvailableCell = ({ cell }: Props) => {
  const quantity = Number(cell.getValue());

  if (quantity <= 0) {
    return <span className="text-primary-brand text-body-md whitespace-nowrap">Out of stock</span>;
  }
  if (quantity < 10) {
    return (
      <span className="text-primary-brand text-body-md whitespace-nowrap">
        {quantity} btls left
      </span>
    );
  }
  return <>{quantity}</>;
};
