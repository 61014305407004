import { IconProps } from './types';

export const IconInvoicePdf = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="25"
      height="28"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.55177 14.3467C6.55177 13.5929 6.02805 13.1425 5.1046 13.1425C4.72769 13.1425 4.47277 13.1792 4.33887 13.215V15.6362C4.49757 15.6719 4.69198 15.6848 4.9588 15.6848C5.94374 15.6848 6.55177 15.1858 6.55177 14.3467ZM12.271 13.1664C11.8574 13.1664 11.5896 13.2031 11.4309 13.2398V18.6039C11.5896 18.6406 11.8445 18.6406 12.0756 18.6406C13.7539 18.6525 14.8489 17.728 14.8489 15.7701C14.8618 14.067 13.864 13.1664 12.271 13.1664Z"
        fill="#DA001A"
      />
      <path
        d="M23.228 10.0151H22.5555V6.77063L22.5496 6.70913C22.5502 6.57872 22.5041 6.45239 22.4196 6.35304L17.0208 0.187469L17.0168 0.183501C16.9481 0.109253 16.8608 0.0547903 16.7639 0.0257914L16.7341 0.0158723C16.693 0.00520699 16.6507 -0.000126573 16.6082 2.27985e-06H3.33968C2.73364 2.27985e-06 2.24167 0.49297 2.24167 1.09802V10.0151H1.56917C0.702256 10.0151 0 10.7173 0 11.5843V19.7455C0 20.6114 0.703248 21.3147 1.56917 21.3147H2.24167V26.902C2.24167 27.507 2.73364 28 3.33968 28H21.4575C22.0626 28 22.5555 27.507 22.5555 26.902V21.3147H23.228C24.0949 21.3147 24.7972 20.6114 24.7972 19.7455V11.5843C24.7972 10.7173 24.0939 10.0151 23.228 10.0151ZM3.33968 1.09802H16.0587V6.71607C16.0587 7.01959 16.3046 7.26459 16.6082 7.26459H21.4585V10.0151H3.33968V1.09802ZM16.8194 15.7085C16.8194 17.2777 16.2481 18.3608 15.4556 19.0294C14.5927 19.7465 13.2794 20.0877 11.6735 20.0877C10.7124 20.0877 10.0319 20.0262 9.56874 19.9667V11.9136C10.2502 11.8045 11.1379 11.744 12.0752 11.744C13.6315 11.744 14.6413 12.0237 15.4318 12.6198C16.2838 13.2516 16.8194 14.2614 16.8194 15.7085ZM2.50055 20.0034V11.9136C3.07188 11.8164 3.8753 11.744 5.00606 11.744C6.1497 11.744 6.96503 11.9632 7.51156 12.4006C8.03429 12.8142 8.38641 13.4956 8.38641 14.2981C8.38641 15.1015 8.11959 15.7819 7.63257 16.2441C6.99975 16.8403 6.06341 17.1081 4.96836 17.1081C4.72535 17.1081 4.50615 17.0962 4.33653 17.0714V20.0034H2.50055ZM21.4575 26.6044H3.33968V21.3147H21.4575V26.6044ZM23.0485 13.325H19.8973V15.1987H22.8412V16.7074H19.8973V20.0034H18.0375V11.8045H23.0485V13.325Z"
        fill="#DA001A"
      />
    </svg>
  );
};
