import { useState, useRef, useLayoutEffect } from 'react';
import clsx from 'clsx';
import { IconChevronDown } from '../../../../assets/icons';
import { Button } from '../../../../components';

type ProductDetailsModalDescriptionProps = {
  text: string;
};

export const ProductDetailsModalDescription = ({ text }: ProductDetailsModalDescriptionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(true);
  const textRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (textRef.current) {
      setIsOverflowing(textRef.current.scrollHeight > 60);
    }
  }, [text]);

  return (
    <div className="flex flex-col border-y border-y-grey-5 pt-6 pb-4 gap-1">
      <div
        ref={textRef}
        className={clsx(
          'transition-all duration-300 text-body-md font-subtitle',
          !isExpanded && isOverflowing ? 'max-h-[60px] overflow-hidden relative' : 'max-h-none',
        )}
      >
        {text}
        {!isExpanded && isOverflowing && (
          <div className="absolute bottom-0 left-0 w-full h-10 bg-gradient-to-t from-white to-transparent" />
        )}
      </div>

      {isOverflowing && (
        <div className="flex justify-end">
          <Button
            onClick={() => setIsExpanded(!isExpanded)}
            icon={
              <IconChevronDown
                className={clsx('transition-transform', {
                  'transform rotate-180': isExpanded,
                  'transform rotate-0': !isExpanded,
                })}
              />
            }
            variant="grey"
            size="small"
            iconPosition="end"
          >
            {isExpanded ? 'Show less' : 'Show more'}
          </Button>
        </div>
      )}
    </div>
  );
};
