import { useRef } from 'react';
import {
  useOverlay,
  usePreventScroll,
  useModal,
  OverlayContainer,
  DismissButton,
  OverlayProvider,
} from '@react-aria/overlays';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { IconClose } from '../../assets/icons';
import { Button } from '../Button';
import { ModalProps } from './types';
import clsx from 'clsx';

const ModalContent = ({
  isOpen,
  onClose,
  title,
  children,
  className,
  titleClassName,
  closeButtonClassName,
}: ModalProps) => {
  const ref = useRef<HTMLDivElement>(null);
  usePreventScroll();

  const { modalProps } = useModal();
  const { overlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      isDismissable: true,
      shouldCloseOnBlur: false,
    },
    ref,
  );
  const { dialogProps, titleProps } = useDialog({}, ref);

  if (!isOpen) return null;

  return (
    <OverlayContainer>
      <div
        className="fixed inset-0 z-50 flex items-center justify-center bg-grey-1 bg-opacity-40"
        onClick={onClose}
      >
        <FocusScope restoreFocus>
          <div
            {...mergeProps(overlayProps, dialogProps, modalProps)}
            ref={ref}
            className={clsx(
              'bg-white outline-none rounded-2.5 py-10 px-6 relative text-center',
              className,
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <p
              {...titleProps}
              className={clsx('text-headline-sm font-headline mb-6', titleClassName)}
            >
              {title}
            </p>
            <div>{children}</div>
            <Button
              icon={<IconClose />}
              variant="grey"
              onClick={onClose}
              className={clsx('!absolute top-3 right-3', closeButtonClassName)}
            />
            <DismissButton onDismiss={onClose} />
          </div>
        </FocusScope>
      </div>
    </OverlayContainer>
  );
};

export const Modal = (props: ModalProps) => (
  <OverlayProvider>
    <ModalContent {...props} />
  </OverlayProvider>
);
