import type { DiscountResponse, Product } from '../../../../redux/api/retailerOrderListApi/types';
import { Price } from '../../../../components/shared';
import { useCart } from '../../../../layout/cart/useCart';
import { useState } from 'react';
import { Button, Counter } from '../../../../components';
import { IconAdd } from '../../../../assets/icons';
import { removeLeadingZeros } from '../../../../utils/removeLeadingZeros';
import { showToastify } from '../../../../utils/common';
import { ToggleButtonGroup } from '../../../../components/ToggleButtonGroup';
import { ProductDetailsModalDiscountedPrice } from './ProductDetailsModalDiscountedPrice';

type Props = {
  product: Product;
  productsDiscounts: Record<string, DiscountResponse>;
  isLoadingDiscounts?: boolean;
};

export const ProductDetailsModalActions = ({
  product,
  productsDiscounts,
  isLoadingDiscounts,
}: Props) => {
  const { changeProductQuantity, getProductQuantityInCart, removeProductsFromCart } = useCart();

  const currentProductCount = getProductQuantityInCart(product);

  const [countValue, setCountValue] = useState(1);
  const [inputValue, setInputValue] = useState('1');

  const [selectedTypeToBuy, setSelectedTypeToBuy] = useState('bottle');

  const handleQuantityChange = (updatedQuantity: number) => {
    if (updatedQuantity > 0) {
      changeProductQuantity(product, updatedQuantity);
    } else {
      removeProductsFromCart([product.productId]);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    value = value.replace(/[^0-9.]/g, '');

    const cleanedValue = removeLeadingZeros(value);

    if (cleanedValue === '' || cleanedValue === '0') {
      setCountValue(1);
      setInputValue('1');
    } else if (Number(cleanedValue) >= product.unitsAvailable) {
      setInputValue(product.unitsAvailable.toString());
      setCountValue(product.unitsAvailable);
    } else {
      setInputValue(cleanedValue);
      setCountValue(Number(cleanedValue));
    }
  };

  const handleAddToCart = () => {
    const bottlesCount =
      selectedTypeToBuy === 'bottle' ? countValue : countValue * (product.unitsPerCase || 1);

    handleQuantityChange(bottlesCount);
    showToastify('success', 'Product have been successfully added to cart');
  };

  if (currentProductCount) {
    return (
      <div className="flex justify-between items-center gap-4 p-6 pb-4">
        <div className="flex flex-col">
          <div className="flex text-subtitle-sm items-center gap-1">
            <Price className="font-subtitle" value={product.unitPrice} />
            <span>/</span>
            <span className="text-body-sm">Bottle</span>
          </div>
          <div className="text-body-sm">Available: {product.unitsAvailable}</div>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <ProductDetailsModalDiscountedPrice
              product={product}
              initialDiscounts={productsDiscounts}
              isLoading={isLoadingDiscounts}
              className="items-end"
            />
          </div>
          <div>
            <Counter
              className="m-auto w-[100px]"
              currentCount={currentProductCount}
              max={product.unitsAvailable}
              min={0}
              onCountChange={handleQuantityChange}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-between items-center gap-4 p-6 pb-4">
      <div className="flex">
        <ToggleButtonGroup
          options={[
            { label: 'Bottle(s)', value: 'bottle' },
            { label: 'Case(s)', value: 'case' },
          ]}
          onChange={(value) => setSelectedTypeToBuy(value)}
          variant="secondary"
        />
      </div>
      <div className="flex gap-6 items-center">
        <div className="flex w-[70px] h-10 border border-grey-4 rounded-2.5 overflow-hidden items-center justify-between">
          <input
            value={inputValue || '1'}
            onChange={(value) => handleInputChange(value)}
            type="text"
            className="py-2.5 pl-3 text-body-md font-body text-left text-grey-1 placeholder-grey-3 bg-transparent w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          />
        </div>
        <Button
          disabled={product.unitsAvailable === 0 || countValue === 0 || inputValue === '0'}
          variant="primary"
          size="small"
          icon={<IconAdd />}
          className="m-auto h-10 items-center"
          onClick={handleAddToCart}
        >
          Add to Cart
        </Button>
      </div>
    </div>
  );
};
