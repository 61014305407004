type Props = {
  label: string;
  value: string;
};

export const GeneralInfoElement = ({ label, value }: Props) => {
  return (
    <div className="flex gap-2">
      <div className="min-w-[104px] w-[104px] text-body-md font-headline">{label}</div>
      <div className="text-body-md whitespace-nowrap overflow-hidden text-ellipsis">{value}</div>
    </div>
  );
};
