import type { Product } from '../../../../redux/api/retailerOrderListApi/types';
import { GeneralInfoElement } from './GeneralInfoElement';

type Props = {
  product: Product;
};

export const ProductDetailsModalGeneralInfo = ({ product }: Props) => {
  return (
    <div className="p-6 bg-grey-7 flex gap-8 whitespace-nowrap overflow-hidden text-ellipsis">
      <div className="flex flex-col gap-2 w-[256px]">
        <GeneralInfoElement label="Type" value={product.itemType ?? '-'} />
        <GeneralInfoElement label="Color" value={product.color ?? '-'} />
        <GeneralInfoElement label="Country" value={product.country ?? '-'} />
        <GeneralInfoElement label="Varietal" value={product.varietal ?? '-'} />
      </div>
      <div className="flex flex-col gap-2 w-[256px]">
        <GeneralInfoElement label="Size" value={product.size ?? '-'} />
        <GeneralInfoElement label="Vintage" value={product.vintage ?? '-'} />
        <GeneralInfoElement label="Appellation" value={product.appellation ?? '-'} />
        <GeneralInfoElement label="Producer" value={product.familyBrand ?? '-'} />
      </div>
    </div>
  );
};
