import type { ProductsFilter } from '../constants';
import { SelectOption } from '../../../components/Select/types';

export const productFiltersToQueryString = (
  filters: ProductsFilter,
  page: number,
  search: string,
  title: string,
  sortingOrder: string | undefined,
  pageItemsCount: number,
  isOnlyAvailable: boolean,
  isOnlyNew: boolean,
  distributorsFilterDebounced: SelectOption<string>[],
): string => {
  const params = new URLSearchParams();
  if (filters) {
    filters.forEach(({ id: key, value }) => {
      value.forEach((val) => {
        if (val) {
          params.append(key, val.toString());
        }
      });
    });
  }

  params.append('page', page.toString());
  params.append('limit', pageItemsCount.toString());

  if (search?.trim()) {
    params.append('search', search);
  }

  if (title) {
    params.append('sortBy', title);
  }

  if (sortingOrder) {
    params.append('order', sortingOrder);
  }

  if (isOnlyAvailable) {
    params.append('inStock', `${isOnlyAvailable}`);
  }

  if (isOnlyNew) {
    params.append('createdIn', 'lastWeek');
  }

  if (distributorsFilterDebounced) {
    distributorsFilterDebounced.forEach((distributorOption) => {
      if (distributorOption) {
        params.append('distributorIds', distributorOption.value);
      }
    });
  }

  return params.toString();
};
