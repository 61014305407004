import type { CellContext } from '@tanstack/react-table';
import type { DiscountResponse, Product } from '../../../redux/api/retailerOrderListApi/types';
import { IconLoader } from '../../../assets/icons';
import { Price } from '../../../components/shared';
import { getPriceValue } from '../../../utils/getPriceValue';

type Props = {
  cell: CellContext<Product, string | number>;
  initialDiscounts: Record<string, DiscountResponse>;
  isLoading?: boolean;
};

export const DiscountAppliedCell = ({ cell, initialDiscounts, isLoading }: Props) => {
  const currentProductDiscounts = initialDiscounts[cell.row.original.productId];

  if (isLoading) {
    return (
      <div className="flex justify-start">
        <IconLoader className="text-grey-3" />
      </div>
    );
  }

  if (!currentProductDiscounts) {
    return null;
  }
  return <Price value={getPriceValue(currentProductDiscounts.discountAmount)} />;
};
