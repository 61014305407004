import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const environment = process.env.REACT_APP_ENVIRONMENT;

const isProduction = environment === 'production';

const replaysSessionSampleRate = isProduction ? 0.1 : 0;
const replaysOnErrorSampleRate = isProduction ? 1.0 : 0;

const integrations = [
  Sentry.reactRouterV6BrowserTracingIntegration({
    useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
  }),
];

if (isProduction) {
  integrations.push(Sentry.replayIntegration());
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations,
  tracesSampleRate: 1.0,
  environment,
  replaysSessionSampleRate,
  replaysOnErrorSampleRate,
});
