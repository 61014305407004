export const formatCountsArray = (passedCounts: number[]): string => {
  const numbers = [...passedCounts];
  if (numbers.length === 0) {
    return '';
  }

  if (numbers.length === 1) {
    return `${numbers[0]}`;
  }

  const lastNumber = numbers.pop();
  return `${numbers.join(', ')} or ${lastNumber}`;
};
