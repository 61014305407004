import type { ButtonProps as AriaButtonProps } from 'react-aria-components';
import type { PropsWithChildren, ReactNode } from 'react';
import { Button as AriaButton } from 'react-aria-components';
import { IconLoader } from '../assets/icons';
import clsx from 'clsx';

export type ButtonVariant = 'primary' | 'secondary' | 'white' | 'text' | 'grey';
type ButtonSize = 'small' | 'medium' | 'large';

export type ButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  isLoading?: boolean;
  icon?: ReactNode;
  iconPosition?: 'start' | 'end';
  onClick?: AriaButtonProps['onPress'];
  type?: AriaButtonProps['type'];
  stopPropogation?: boolean;
  className?: string;
} & PropsWithChildren;

const createButtonStyles = (props: ButtonProps) => {
  const { variant = 'primary', size = 'medium', disabled, children, icon } = props;

  const baseClasses = 'rounded-2.5 outline-none';
  const paddingClasses = clsx({
    'py-3': children && size === 'large',
    'py-[7px]': children && size === 'medium',
    'py-1': children && size === 'small' && icon,
    'py-1.5': children && size === 'small' && !icon,
    'p-4': !children && size === 'large',
    'p-2': !children && size === 'medium',
    'p-[3px]': !children && size === 'small',
  });
  const textSizeClasses = clsx({
    'text-button-lg': size === 'large',
    'text-button-md': size === 'medium',
    'text-button-sm': size === 'small',
  });
  const fontWeightClasses = clsx({
    'font-button': ['large', 'small'].includes(size),
    'font-subtitle': size === 'medium',
  });
  const disabledClasses = clsx({
    '!cursor-not-allowed': disabled,
  });

  const variantClasses = {
    primary: clsx('text-white', {
      'px-5': children,
      '!bg-primary-brand active:!bg-primary-brand hover:!bg-primary-brand-dark': !disabled,
      'disabled:!bg-primary-brand-light': disabled,
    }),
    secondary: clsx('border', {
      'px-5': children,
      'border-primary-brand text-primary-brand active:text-primary-brand active:border-primary-brand hover:border-primary-brand-dark hover:text-primary-brand-dark':
        !disabled,
      'disabled:border-primary-brand-light text-primary-brand-light': disabled,
    }),
    white: clsx('bg-white', {
      'px-5': children,
      'text-primary-brand active:text-primary-brand hover:text-primary-brand-dark': !disabled,
      'text-primary-brand-light': disabled,
    }),
    text: clsx({
      'text-primary-brand active:text-primary-brand hover:text-primary-brand-dark': !disabled,
      'text-primary-brand-light': disabled,
    }),
    grey: clsx({
      'text-grey-1 active:text-grey-1 hover:text-primary-brand-dark': !disabled,
      'text-grey-3': disabled,
    }),
  };

  return clsx(
    baseClasses,
    paddingClasses,
    textSizeClasses,
    fontWeightClasses,
    variantClasses[variant],
    disabledClasses,
  );
};

export const Button = (props: ButtonProps) => {
  const {
    isLoading,
    icon,
    iconPosition = 'start',
    children,
    type,
    onClick,
    stopPropogation,
    className,
  } = props;
  return (
    <AriaButton
      onPress={(e) => {
        onClick?.(e);
        if (!stopPropogation) {
          e.target.parentElement?.click();
        }
      }}
      isDisabled={props.disabled}
      type={type}
      className={clsx(
        'flex relative transition-colors duration-200 outline-none',
        createButtonStyles(props),
        className,
      )}
    >
      {isLoading && (
        <IconLoader className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 " />
      )}
      <div
        className={clsx('flex items-center', {
          'opacity-0 pointer-none': isLoading,
          'gap-2': !isLoading && Boolean(children),
        })}
      >
        {Boolean(icon && iconPosition === 'start') && icon}
        <span>{props.children}</span>
        {Boolean(icon && iconPosition === 'end') && icon}
      </div>
    </AriaButton>
  );
};
