import { formatCountsArray } from '../../../utils/formatCountsArray';
import { IconInfoSmall } from '../../../assets/icons';
import clsx from 'clsx';

type Props = {
  discounts: number[];
  onInfoIconClick: () => void;
  isCurrentDiscountTooltipOpen: boolean;
  discountTypeText: string;
  'data-tooltip-id': string;
};
export const DiscountVariantRow = ({
  discounts,
  discountTypeText,
  isCurrentDiscountTooltipOpen,
  onInfoIconClick,
  'data-tooltip-id': dataTooltipId,
}: Props) => {
  return (
    <div className="flex gap-2 items-center">
      <div className="whitespace-nowrap">
        {formatCountsArray(discounts)} {discountTypeText}
      </div>
      <div data-tooltip-id={dataTooltipId} onClick={onInfoIconClick}>
        <IconInfoSmall
          className={clsx('cursor-pointer transition-colors', {
            'text-grey-4 hover:text-grey-1': !isCurrentDiscountTooltipOpen,
            'text-primary-brand hover:text-primary-brand-dark': isCurrentDiscountTooltipOpen,
          })}
        />
      </div>
    </div>
  );
};
