import { Modal } from '../../../../components';
import type { ModalProps } from '../../../../components/Modal/types';
import clsx from 'clsx';
import type { DiscountResponse, Product } from '../../../../redux/api/retailerOrderListApi/types';
import { ProductDetailsModalGeneralInfo } from './ProductDetailsModalGeneralInfo';
import { ProductDetailsModalDiscountsAvailable } from './ProductDetailsModalDiscountsAvailable';
import { Price, ProductImage } from '../../../../components/shared';
import { Scrollbars } from 'rc-scrollbars';
import { ProductDetailsModalDescription } from './ProductDetailsModalDescription';
import { ProductDetailsModalActions } from './ProductDetailsModalActions';
import { useMemo } from 'react';

type Props = ModalProps & {
  product: Product;
  productsDiscounts: Record<string, DiscountResponse>;
  isLoadingDiscounts: boolean;
};

export const ProductDetailsModal = ({
  product,
  onClose,
  className,
  productsDiscounts,
  isLoadingDiscounts,
  ...props
}: Props) => {
  const currentProductDiscounts = productsDiscounts[product.productId];
  const maxHeight = useMemo(() => window.innerHeight - 192, []);

  return (
    <Modal
      onClose={onClose}
      className={clsx(className, 'w-full max-w-[640px] !px-0 !py-0 text-start')}
      {...props}
    >
      <div className="flex flex-col">
        <div className="border-y border-y-grey-6">
          <Scrollbars autoHeight autoHeightMax={maxHeight}>
            <div className="flex flex-col p-6  gap-6 mb-0">
              <div className="flex gap-4">
                <ProductImage
                  className="!min-w-[260px] !w-[260px] !h-[260px]"
                  src={product.azImageUrl}
                />
                <div className="flex flex-col gap-2">
                  <div className="mb-1 text-headline-md font-headline">
                    Product name: {product.title}
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-headline-md font-headline">
                      <Price value={product.unitPrice} /> /
                    </span>
                    <span> Bottle</span>
                  </div>
                  <div className="text-subtitle-sm font-headline">Discounts Available:</div>
                  {!!currentProductDiscounts && productsDiscounts && product && (
                    <ProductDetailsModalDiscountsAvailable
                      product={product}
                      productsDiscounts={productsDiscounts}
                      currentProductDiscounts={currentProductDiscounts}
                    />
                  )}
                </div>
              </div>
              {product.description && <ProductDetailsModalDescription text={product.description} />}
              <ProductDetailsModalGeneralInfo product={product} />
            </div>
          </Scrollbars>
        </div>

        <ProductDetailsModalActions
          product={product}
          productsDiscounts={productsDiscounts}
          isLoadingDiscounts={isLoadingDiscounts}
        />
      </div>
    </Modal>
  );
};
