import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import {
  AddPlatformFeePercentageRequestBody,
  DownloadInvoiceRequestBody,
  GetPlatformFeePercentageRequestBody,
  GetPlatformFeePercentageRequestData,
  OutstandingPaymentRequestData,
  Invoice,
} from './types';

export const platformFeesApi = createApi({
  reducerPath: 'platformFeesApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getInvoices: builder.query<{ data: Invoice[]; total: number }, string>({
      query: (query: string) => ({
        url: `/api/v2/invoices?${query}`,
        method: 'GET',
      }),
    }),
    getInvoiceReport: builder.mutation<ApiResponse<null>, null>({
      query: (body) => ({
        url: 'api/getInvoiceReport',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getOutstandingPayment: builder.mutation<ApiResponse<OutstandingPaymentRequestData>, void>({
      query: () => ({
        url: 'api/getOutstandingPayment',
        method: 'GET',
      }),
    }),
    getPlatformFeeByUserId: builder.mutation<ApiResponse<null>, null>({
      query: (body) => ({
        url: 'api/getPlatformFeesByUserId',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getPlatformFeePercentage: builder.mutation<
      ApiResponse<GetPlatformFeePercentageRequestData>,
      GetPlatformFeePercentageRequestBody
    >({
      query: (body) => ({
        url: 'api/getPlatformFeePercentage',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    addPlatformFeePercentage: builder.mutation<
      ApiResponse<null>,
      AddPlatformFeePercentageRequestBody
    >({
      query: (body) => ({
        url: 'api/addPlatformFeePercentage ',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    addPlatformFeePercentageDownload: builder.mutation<
      ApiResponse<null>,
      DownloadInvoiceRequestBody
    >({
      query: (body) => ({
        url: '/api/downloadInvoice',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetInvoiceReportMutation,
  useGetOutstandingPaymentMutation,
  useGetPlatformFeeByUserIdMutation,
  useGetPlatformFeePercentageMutation,
  useAddPlatformFeePercentageMutation,
  useAddPlatformFeePercentageDownloadMutation,
} = platformFeesApi;
