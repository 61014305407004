import type { CellContext } from '@tanstack/react-table';
import type { DiscountResponse, Product } from '../../../redux/api/retailerOrderListApi/types';
import { IconLoader } from '../../../assets/icons';
import { BottleDiscountTooltipContent } from '../../../components/shared/Discounts/BottleDiscountTooltipContent';
import { Tooltip } from 'react-tooltip';
import { useEffect, useRef, useState } from 'react';
import { CaseDiscountTooltipContent } from '../../../components/shared/Discounts/CaseDiscountTooltipContent';
import { Portal } from '../../../components/Portal';
import { DiscountVariantRow } from '../../../components/shared/Discounts/DiscountVariantRow';

type Props = {
  cell: CellContext<Product, string | number>;
  initialDiscounts: Record<string, DiscountResponse>;
  isLoading?: boolean;
  productsDiscounts: Record<string, DiscountResponse>;
};

export const AvailableDiscountsCell = ({
  cell,
  initialDiscounts,
  isLoading,
  productsDiscounts,
}: Props) => {
  const tooltipsPortalContainerRef = useRef<HTMLElement | null>(
    document.getElementById('discountsTooltips'),
  );
  const currentProductDiscounts = initialDiscounts[cell.row.original.productId];
  const [openedDiscountModalType, setOpenedDiscountModalType] = useState<
    'byUnit' | 'byCase' | null
  >(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setOpenedDiscountModalType(null);
    }
  }, [isOpen]);

  if (isLoading) {
    return <IconLoader className="text-grey-3" />;
  }
  if (!currentProductDiscounts) {
    return null;
  }

  return (
    <div>
      {!!currentProductDiscounts.discountOnUnits.length && (
        <DiscountVariantRow
          discounts={currentProductDiscounts.discountOnUnits}
          onInfoIconClick={() => setOpenedDiscountModalType('byUnit')}
          isCurrentDiscountTooltipOpen={isOpen && openedDiscountModalType === 'byUnit'}
          discountTypeText="bottles"
          data-tooltip-id={`discountsTooltip_${cell.row.original.productId}`}
        />
      )}
      {!!currentProductDiscounts.discountOnCase.length && (
        <DiscountVariantRow
          discounts={currentProductDiscounts.discountOnCase}
          onInfoIconClick={() => setOpenedDiscountModalType('byCase')}
          isCurrentDiscountTooltipOpen={isOpen && openedDiscountModalType === 'byCase'}
          discountTypeText="cases"
          data-tooltip-id={`discountsTooltip_${cell.row.original.productId}`}
        />
      )}
      <Portal node={tooltipsPortalContainerRef.current}>
        <Tooltip
          setIsOpen={setOpen}
          isOpen={isOpen}
          delayHide={0}
          delayShow={0}
          opacity={1}
          className="!bg-white shadow-tooltip z-50 !rounded-2.5 !p-0"
          openOnClick
          clickable
          id={`discountsTooltip_${cell.row.original.productId}`}
          place="bottom-end"
        >
          {isOpen && (
            <>
              {openedDiscountModalType === 'byUnit' ? (
                <BottleDiscountTooltipContent
                  onClose={() => setOpen(false)}
                  productsDiscounts={productsDiscounts}
                  product={cell.row.original}
                />
              ) : (
                <CaseDiscountTooltipContent
                  onClose={() => setOpen(false)}
                  productsDiscounts={productsDiscounts}
                  product={cell.row.original}
                />
              )}
            </>
          )}
        </Tooltip>
      </Portal>
    </div>
  );
};
